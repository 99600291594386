<!-- 收款方式管理 -->
<template>
    <div class="viewsPages">
        <div class="views-BankCard">
            <!-- 列表界面 -->
            <div v-show="showList" class="BankCard-list">
                <div class="BankCard-list">
                    <!-- 列表 -->
                    <div class="BankCard-item" v-for="(item, i) in userBankList" :key="i">
                        <div class="BankCard-img">
                            <img loading="lazy" v-lazy="systemImgHost + item.imgUrl" />
                        </div>
                        <div class="BankCard-name">{{ item.name }}</div>
                        <!-- <div>{{ item.branch }}</div> -->
                        <div class="BankCard-number">{{ item._hideMiddleNumber }}</div>
                    </div>
                </div>
                <Empty v-if="!userBankList.length" :description='$t("userPage.text49")' :image="require('@/assets/images/my/null.png')"></Empty>
                <div class="BankCard-add">
                    <!-- 添加 -->
                    <div class="BankCardAdd-item" v-if="bankCardList.length < userBankMax" @click="goAdd(0)">
                        <i class="add"></i>
                        <div>{{$t("userPage.text50")}}</div>
                    </div>
                    <div class="BankCardAdd-item" v-if="usdtList.length < userUsdtMax" @click="goAdd(1)">
                        <i class="add"></i>
                        <div>{{$t("userPage.text51")}}</div>
                    </div>
                    <!-- <div class="BankCardAdd-item" v-if="origoList.length < userOrigoMax" @click="goAdd(2)">
                        <i class="add"></i>
                        <div>{{$t("userPage.text52")}}</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Empty } from 'element-ui'
import { _getBankCountConfig, _getBindSmsSetting, _getBanks } from '@/core/api/modules/system'
import { _getBankList} from '@/core/api/modules/user'
import { _checkAddStatus } from '@/core/comp/pages/userInfo'
export default {
    components: {
        Empty
    },
    data () {
        return {
            showList: true, // true-银行卡列表  false-添加银行卡
            form: {
                name: '', // 银行
                branch: '', // 支行（）
                realName: '', // 真实姓名（）
                number: '', // 银行卡号（）
                number2: '', // 确认卡号（）
                code: '', // 手机验证码
            },
            smsTimeout: 0, // 短信倒计时
        }
    },
    computed: {
        isUSDT () { // 是否是数字货币
            return this.form.name == '数字货币'
        },
        ...mapGetters([
            'userBankList', // 绑定的银行卡列表
            'userData', // 用户数据
            'userBankMax', // 最多银行卡绑定多少张
            'userUsdtMax', // 最多Usdt绑定多少个
            'userOrigoMax', // 最多origo绑定多少个
            'userBanks', // 可选银行列表
            'systemImgHost',
        ]),
        bankCardList(){
            return this.userBankList.filter(item => { return item.type == 0});
        },
        usdtList(){
            return this.userBankList.filter(item => { return item.type == 1});
        },
        origoList(){
            return this.userBankList.filter(item => { return item.type == 2});
        },
    },
    created () {
        _getBankCountConfig()
        _getBindSmsSetting()
        setTimeout(() => {
            _getBanks()
            _getBankList()
        }, 500)
        setTimeout(() => {
            console.log('----我的卡', this.userBankList,'---最多绑定',this.userBankMax)
        }, 3000)
    },
    methods: {
        
        // 去添加银行卡
        goAdd (tab) {
            _checkAddStatus().then(res => {
                console.log('---res', res)
                switch (res) {
                    case 'realName': // 去绑定真实姓名
                    case 'phone': // 去绑定手机
                        this.$router.push({
                            name: 'userInfo',
                        })
                        break
                    case 'withdrawPassWordState': // 去设置资金密码
                        this.$router.push({
                            name: 'fundPass',
                            // query: {
                            //     tab: 1,
                            //     t: Date.now()
                            // }
                        })
                        break
                    case 'pass': // 通过验证，去绑卡
                        // this.resetForm()
                        // this.$router.push({path: 'bankAdd'})
                        this.$router.push({
                            name: 'bankAdd',
                            query: {
                                tab: tab,
                                t: Date.now()
                            }
                        })
                        break
                }
            })
        },
     }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/my/';
.views-BankCard{
    width: 1100px;
    margin: 20px auto;
    padding: 10px 40px;
    border-radius: 10px;
    background: #DEDEDE;
    .BankCard-list{
        width: 100%;
        padding-bottom: 100px;
        .BankCard-list {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 20px 10px;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-top: 20px;
        }
        .BankCard-item {
            // flex: 1;
            // width: 270px;
            // min-width: 270px;
            padding: 30px 10px;
            display: grid;
            justify-content: flex-start;
            grid-auto-flow: column dense;
            align-items: center;
            flex-wrap: wrap;
            border-radius: 6px;
            background: #ce06cd;
            box-shadow: 0 2px 0px 0 #ce06cd;
            .BankCard-name{
                font-size: 20px;
                flex: 1;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #fff;
            }
            .BankCard-number{
                font-size: 18px;
                flex: 1;
                display: flex;
                align-items: center;
                color: #fff;
            }
            .BankCard-img{
                width: 80px;
                height: 80px;
                grid-column: 1 / 3;
                grid-row: 1 / 3;
                margin:0 10px;
                display:flex;
                align-items: center;
                img{
                    width: 100%;
                    height:auto;
                    border-radius: 10px;
                }
            }
        }
        
        .BankCard-add{
            margin-top: 100px;
            display:flex;
            justify-content:center;
            align-items: center;
            .BankCardAdd-item{
                min-width: 270px;
                height: 140px;
                display:flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                flex-wrap: wrap;
                cursor: pointer;
                border-radius: 6px;
                margin: 0 20px;
                background:transparent;
                box-shadow: none;
                border:1px dashed #ce06cd;
                .add{
                    width: 64px;
                    height: 64px;
                    margin: 0 auto 10px;
                    display:block;
                    background:url(#{$imgUrl}bankCard-add.png)no-repeat center/contain;
                }
                &>div{
                    text-align:center;
                    font-size: 18px;
                    line-height:40px;
                    font-weight: 700;
                    width: 100%;
                }
            }
        }
    }
}
</style>